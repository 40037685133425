import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./financial-service@gen.scss";

const GeneratedIndustriesFinancialService = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          金融サービス業でのPagerDuty活用法｜正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="金融サービス企業は、最新のシステムとレガシーシステムを横断するハイブリッド運用の可視化とオーケストレーションを実現しながら、イノベーションを加速させる必要があります。PagerDutyは、データをインサイトとアクションに変換し、急速に進化する顧客ニーズに対応する世界トップティアの金融サービス企業群を支援しています。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="金融サービス企業は、最新のシステムとレガシーシステムを横断するハイブリッド運用の可視化とオーケストレーションを実現しながら、イノベーションを加速させる必要があります。PagerDutyは、データをインサイトとアクションに変換し、急速に進化する顧客ニーズに対応する世界トップティアの金融サービス企業群を支援しています。"
        />

        <meta
          property="og:title"
          content="金融サービス業でのPagerDuty活用法｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="金融サービス企業は、最新のシステムとレガシーシステムを横断するハイブリッド運用の可視化とオーケストレーションを実現しながら、イノベーションを加速させる必要があります。PagerDutyは、データをインサイトとアクションに変換し、急速に進化する顧客ニーズに対応する世界トップティアの金融サービス企業群を支援しています。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/industries/financial-service/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/industries/financial-service/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solutions"
        textGreen="金融サービス業でのPagerDuty活用法"
        overview=""
      />

      <div className="financial-wrapper">
        <div className="center-2 first-section">
          <p className="center-para">
            金融サービス企業は、最新のシステムとレガシーシステムを横断するハイブリッド運用の可視化とオーケストレーションを実現しながら、イノベーションを加速させる必要があります。PagerDutyは、データをインサイトとアクションに変換し、急速に進化する顧客ニーズに対応する世界トップティアの金融サービス企業群を支援しています。
          </p>
        </div>
        <div className="text-right-1">
          <div className="text-part">
            <h3 className="text-right-title">金融サービスを革新する機能</h3>
            <p className="text-right-para">
              現代の金融サービスには、デジタル／アナログを問わず安全に即時送金、支払い、取引、投資を行うための革新性と利便性が求められています。PagerDutyの金融サービスソリューションは、サービスの中断を迅速に防ぎ、バックオフィスプロセスを近代化。カスタマーファーストのエクスペリエンスをリアルタイムで常に提供できるよう支援します。
            </p>
          </div>
          <div className="img-container">
            <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/industry_financialservices_372f7aec20.png" />
          </div>
        </div>
        <div className="center-1">
          <h2 className="center-title">金融サービスの未来を支える</h2>
        </div>
        <div className="three-col-blog-card-image-grey">
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 39 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.8868 59.2752H28.2008V50.0315H10.8868V59.2752ZM14.0863 56.2984H25V53.0095H14.0863V56.2984Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.0951 47.0114H24.893V40.8987C24.893 36.1783 26.5941 32.582 30.5822 28.873C33.9371 25.753 35.6382 22.1812 35.6382 18.2591C35.6382 10.0024 28.4176 3.28606 19.5408 3.28606C10.6639 3.28606 3.442 10.0024 3.442 18.2591C3.442 22.1812 5.14312 25.7518 8.49928 28.873C12.4874 32.582 14.1872 36.1783 14.1872 40.8987V47.0114H10.9864V40.8987C10.9864 36.9902 9.61053 34.1163 6.23594 30.9779C2.25833 27.2787 0.241211 22.9991 0.241211 18.2591C0.241211 8.36158 8.89822 0.308105 19.5408 0.308105C30.182 0.308105 38.8403 8.36158 38.8403 18.2591C38.8403 22.9991 36.8219 27.2787 32.8442 30.9779C29.471 34.1163 28.0951 36.9902 28.0951 40.8987V47.0114Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.0373 47.0137L17.8351 47.0076L17.8918 19.6794L21.0939 19.6868L21.0373 47.0137Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3766 21.1714H25.553V18.1935H13.3766V21.1714Z"
                  fill="#005A24"
                />
              </svg>
            </div>
            <h4 className="title">
              修正回数を減らし、
              <br />
              イノベーションを促進
            </h4>
            <p className="para">
              アジリティーとDevOpsの変革を推進する、PagerDutyのベストプラクティス。インテリジェントな自動化とビジネスパーソンのオーケストレーションにより、重要な問題を見逃すことはありません。
            </p>
          </div>
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.127197 7.29752C0.127197 10.9093 3.11439 13.8367 6.79987 13.8367C10.4853 13.8367 13.4725 10.9093 13.4725 7.29752C13.4725 3.68576 10.4853 0.757324 6.79987 0.757324C3.11439 0.757324 0.127197 3.68576 0.127197 7.29752Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.1782 7.29752C18.1782 10.9093 21.1654 13.8367 24.8509 13.8367C28.5364 13.8367 31.5246 10.9093 31.5246 7.29752C31.5246 3.68576 28.5364 0.757324 24.8509 0.757324C21.1654 0.757324 18.1782 3.68576 18.1782 7.29752Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M46.7502 7.29752C46.7502 9.37595 45.0235 11.0671 42.9036 11.0671C40.7818 11.0671 39.0571 9.37595 39.0571 7.29752C39.0571 5.21811 40.7818 3.52693 42.9036 3.52693C45.0235 3.52693 46.7502 5.21811 46.7502 7.29752ZM36.23 7.29752C36.23 10.9093 39.2192 13.8367 42.9036 13.8367C46.5891 13.8367 49.5763 10.9093 49.5763 7.29752C49.5763 3.68576 46.5891 0.757324 42.9036 0.757324C39.2192 0.757324 36.23 3.68576 36.23 7.29752Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.6469 24.9886C10.6469 27.067 8.92023 28.7591 6.79938 28.7591C4.67753 28.7591 2.95284 27.067 2.95284 24.9886C2.95284 22.9091 4.67753 21.217 6.79938 21.217C8.92023 21.217 10.6469 22.9091 10.6469 24.9886ZM0.126709 24.9886C0.126709 28.5993 3.1149 31.5278 6.79938 31.5278C10.4849 31.5278 13.472 28.5993 13.472 24.9886C13.472 21.3768 10.4849 18.4484 6.79938 18.4484C3.1149 18.4484 0.126709 21.3768 0.126709 24.9886Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.6979 24.9886C28.6979 27.067 26.9723 28.7591 24.8504 28.7591C22.7296 28.7591 21.0049 27.067 21.0049 24.9886C21.0049 22.9091 22.7296 21.217 24.8504 21.217C26.9723 21.217 28.6979 22.9091 28.6979 24.9886ZM18.1777 24.9886C18.1777 28.5993 21.1659 31.5278 24.8504 31.5278C28.5369 31.5278 31.5241 28.5993 31.5241 24.9886C31.5241 21.3768 28.5369 18.4484 24.8504 18.4484C21.1659 18.4484 18.1777 21.3768 18.1777 24.9886Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M36.2305 24.9886C36.2305 28.5993 39.2187 31.5278 42.9041 31.5278C46.5886 31.5278 49.5768 28.5993 49.5768 24.9886C49.5768 21.3768 46.5886 18.4484 42.9041 18.4484C39.2187 18.4484 36.2305 21.3768 36.2305 24.9886Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.127197 42.6787C0.127197 46.2905 3.11439 49.2189 6.79987 49.2189C10.4853 49.2189 13.4725 46.2905 13.4725 42.6787C13.4725 39.067 10.4853 36.1395 6.79987 36.1395C3.11439 36.1395 0.127197 39.067 0.127197 42.6787Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.6979 42.6787C28.6979 44.7582 26.9723 46.4493 24.8504 46.4493C22.7296 46.4493 21.0049 44.7582 21.0049 42.6787C21.0049 40.6003 22.7296 38.9091 24.8504 38.9091C26.9723 38.9091 28.6979 40.6003 28.6979 42.6787ZM18.1777 42.6787C18.1777 46.2905 21.1659 49.2189 24.8504 49.2189C28.5369 49.2189 31.5241 46.2905 31.5241 42.6787C31.5241 39.067 28.5369 36.1395 24.8504 36.1395C21.1659 36.1395 18.1777 39.067 18.1777 42.6787Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M46.7502 42.6787C46.7502 44.7582 45.0235 46.4493 42.9036 46.4493C40.7818 46.4493 39.0571 44.7582 39.0571 42.6787C39.0571 40.6003 40.7818 38.9091 42.9036 38.9091C45.0235 38.9091 46.7502 40.6003 46.7502 42.6787ZM36.23 42.6787C36.23 46.2905 39.2192 49.2189 42.9036 49.2189C46.5891 49.2189 49.5763 46.2905 49.5763 42.6787C49.5763 39.067 46.5891 36.1395 42.9036 36.1395C39.2192 36.1395 36.23 39.067 36.23 42.6787Z"
                  fill="#005A24"
                />
              </svg>
            </div>
            <h4 className="title">
              レガシーシステム <br />
              の近代化
            </h4>
            <p className="para">
              ストレートスルー処理などのリアルタイムタスクを最適化し、ハイブリッド環境、サイロ化したシステム、中央および分散したチーム全体のワークフローを統合することで業務効率を向上できます。
            </p>
          </div>
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.9024 -0.000488281L-0.000488281 49.7992H49.8029L24.9024 -0.000488281ZM24.9024 7.85914L44.1147 46.2862H5.69015L24.9024 7.85914Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.0625 42.8796H26.7397V37.9604H23.0625V42.8796Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.062 17.8342V33.0383H26.7417V29.8489V21.0921V17.8342H23.062Z"
                  fill="#005A24"
                />
              </svg>
            </div>
            <h4 className="title">
              サイバーセキュリティー
              <br />
              リスクの軽減
            </h4>
            <p className="para">
              SOC、IT、ネットワークチーム間で理想的なインシデントシナリオを可視化・自動化し共有。サイバーセキュリティーリスクを積極的に軽減し、アプリデリバリーライフサイクル全体でセキュリティーを向上させるベストプラクティスを促進します。
            </p>
          </div>
        </div>
        <div className="center-1 mt-0">
          <h2 className="center-title">
            リアルタイムオペレーションが金融サービスを強化する理由
          </h2>
        </div>
        <div className="four-col-without-cta mb-[100px] md:gap-[30px] gap-[50px]">
          <div className="card">
            <h6>セルフサービス型統合</h6>
            <p>
              柔軟なAPIと500以上の統合機能（ServiceNow、Symphonyなど）により、プラットフォームの相互運用性を実現します。
            </p>
          </div>
          <div className="card">
            <h6>リアルタイムのレスポンス</h6>
            <p>
              インテリジェントなコンテキストでトリアージを高速化し、チーム全体で理想的なインシデントシナリオを自動化します。
            </p>
          </div>
          <div className="card">
            <h6>ビジネスインパクトの可視化</h6>
            <p>
              ビジネスに最も大きな影響を与えるサービスの中断を定量化し、優先順位を付け、迅速に解決します。
            </p>
          </div>
          <div className="card">
            <h6>24時間365日のカスタマーサポート</h6>
            <p>
              お客様の問題が自動的に適切なスタッフにエスカレーションされるようになり、チームの能力が拡張されます。
            </p>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedIndustriesFinancialService;
